import React from 'react';

import Layout from '../components/Layout';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';
import Hero from '../components/Hero/Hero';
import Title from '../components/Title/Title';
import ColumnsConnections from '../components/ColumnsConnections/ColumnsConnections';

import heroImg from '../images/solutions/hero.png';
import assistanceImg from '../images/solutions/assistance.png';
import zeroUsdImg from '../images/solutions/zeroUsd.png';

import { GtagHead } from '../components/GTAG';
import Footnotes from '../components/Footnotes/Footnotes';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>Loqtorzi Solutions™ | Loqtorzi™ Patient website</title>
      <meta name='description'content='Loqtorzi Solutions™ offers a range of patient support programs for patients who are prescribed Loqtorzi™'/>
    </>
  )
}

export default function Solutions() {
  return (
    <Layout>
      <Hero image={ { src: heroImg, alt:'Hero LOQTORZI Solutions™' } }>
        <Title>
          <h1>FINANCIAL SUPPORT IS AVAILABLE</h1>
          <h3 className='mt-[1.3rem] tablet:mt-[1.8rem]'>
            LOQTORZI Solutions™, part of the Coherus <br className='hidden large:block' />
            Solutions™ family of support services, offers a range <br className='hidden large:block' />
            of patient support programs for patients who are <br className='hidden large:block' />
            prescribed LOQTORZI™
          </h3>
        </Title>
      </Hero>

      <GlobalWrapper className={'mobileFull'}>
        <section className='box-shadow pb-[4.6rem] tablet:mt-[2.9rem] tablet:pt-[3.6rem] tablet:pb-[6.8rem] tablet:pl-[3.8rem]'>
          <Title>
            <h2 className='uppercase'>Helpful resources</h2>
          </Title>
          <Title className={'mt-[3rem]'}>
            <h4>
              <img className='inline w-[7.8rem] tablet:w-[9.4rem]' src={ zeroUsdImg } alt='$0'/>
              <span>Co-pay savings program</span>
            </h4>
          </Title>
          <p className='mt-[1.9rem]'>Coherus’s LOQTORZI™ Co-Pay Savings Program may cover a portion of out-of-pocket costs associated with LOQTORZI™ for eligible patients with commercial insurance.</p>
          <p className='mt-[1.9rem]'>Under the program, assistance with drug co-pay costs is available up to $30,000 per calendar year. Eligible patients could pay as little as $0 per dose of LOQTORZI™.</p>

          <p className='mt-[1.9rem]'>
            <strong>Patient eligibility criteria</strong>
            <br/>
            <ul className='bullet-list bullet-list--blue mt-[1.8rem]'>
              <li>Be prescribed LOQTORZI™ for a medically appropriate purpose within 180 days of program enrollment</li>
              <li>Have commercial (private or nongovernmental) health insurance that covers the medication costs of LOQTORZI™</li>
              <li>Over the age of 18 years old and a US resident</li>
              <li>Not covered by any federal, state, or government-funded healthcare program, such as Medicare, Medicare Advantage, Medicare Part D, Veterans Affairs, Department of Defense, or TRICARE</li>
              <li>Not seek reimbursement from any third party, including payers, charitable foundation, or flexible spending accounts (FSAs) or healthcare savings accounts (HSAs) for all or any of the benefit received by Coherus through the program</li>
              <li>Other restrictions apply; see Terms and Conditions at LOQTORZISolutions.com</li>
              <li>It is not valid for cash-paying patients or where prohibited by law</li>
              <li>LOQTORZI™ Co-Pay Savings Program is subject to change or discontinuation without notice</li>
            </ul>
          </p>

          <Title className={'mt-[2.8rem]'}>
            <h4>
              <img className='inline w-[7.8rem] tablet:w-[9.4rem]' src={ assistanceImg } alt='Assistance program'/>
              <span>Patient assistance program</span>
            </h4>
          </Title>
          <div className='mt-[1.9rem]'>LOQTORZI™ may be available to you at no cost if you are uninsured, functionally underinsured,* or are a Medicare patient experiencing financial hardship.</div>

          <p className='mt-[1.9rem]'>
            <strong>Financial assistance eligibility criteria</strong>
            <br/>
            <ul className='bullet-list bullet-list--blue mt-[1.8rem]'>
              <li>Be either: (a) uninsured; (b) functionally underinsured*; or (c) traditional Medicare Fee-for-Service (FFS) insured patient(s) who demonstrate financial hardship and cannot afford their cost-sharing obligation as evidenced by a signed attestation from their provider</li>
              <li>Have an adjusted annual household income of ≤500% of Federal Poverty Level (FPL)</li>
              <li>Complete and sign consent form and, when applicable, provide income documentation</li>
              <li>Be under the care of a US licensed provider, and receive LOQTORZI™ in an established practice located in the United States incident to the prescribing physician’s professional services in the outpatient setting</li>
              <li>LOQTORZI Solutions™ may be able to assess patient eligibility for retrospective patient assistance. Please contact LOQTORZI Solutions™ at 1-844-483-3692 for additional information. Medicare patients are not eligible for retrospective patient assistance</li>
              <li>If eligible, the Patient Assistance Program only covers the costs of LOQTORZI™ and does not cover any administration or office visit costs. Restrictions may apply, and the program is not valid where prohibited by law. Coherus may revise or terminate this program without notice at any time for any reason</li>
              <li>Be a US resident</li>
              <li>Diagnosis is for an FDA-approved indication of LOQTORZI™</li>
              <li>Not have any other financial support options</li>
              <li>The patient must receive the drug in an outpatient setting by the physician or physician office</li>
            </ul>
          </p>

          <Footnotes className={'mt-[2.5rem] desktop:mt-[3.2rem]'} items={[
            {
              bullet: '*',
              text: <span>Functionally underinsured means the patient does not have insurance or has insurance by no coverage for LOQTORZI™.</span>
            }
          ]} />

          <div className='w-[86%] mx-auto font-MontserratSemiBold text-blue-600 text-[1.7rem] text-center uppercase mt-[3.5rem] tablet:mt-[6rem] tablet:text-[2rem]'>
          For more financial or practice resources from LOQTORZI <br className='hidden desktop:block'/> Solutions™, call <a href="tel:18444833692" className='whitespace-nowrap no-underline'>1-844-483-3692</a>
          </div>
        </section>
      </GlobalWrapper>

      <GlobalWrapper className={'desktop:pb-[4.5rem]'}>
        <ColumnsConnections className={'mt-[7.9rem] tablet:mt-[5.6rem] desktop:mt-[12rem]'}
          data={[
            {
              title: 'Stay connected',
              link: '/stay-in-touch/',
              text: 'Get more educational tools and resources to help support you on your treatment journey.',
              className: 'is-active'
            },
            {
              title: 'See side effects',
              link: '/loqtorzi-side-effects/',
              text: 'Learn about the possible side effects of LOQTORZI™ treatment.'
            },
            {
              title: 'Explore the results',
              link: '/loqtorzi-clinical-trial-results/',
              text: 'See how LOQTORZI™ may give you the chance for more time without your cancer progressing.'
            }
          ]}/>
      </GlobalWrapper>
    </Layout>
  );
}
